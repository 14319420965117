import { Resource } from "react-admin";

import AssignmentIconDef from "@material-ui/icons/Assignment";
import PhotographersIcon from "@material-ui/icons/CameraEnhance";
import ClassIcon from "@material-ui/icons/Class";
import CommentIcon from "@material-ui/icons/Comment";
import AuthorIcon from "@material-ui/icons/FormatQuote";
import UserIcon from "@material-ui/icons/Group";
import RoleIcon from "@material-ui/icons/HowToReg";
import PagesIcon from "@material-ui/icons/ImportContacts";
import TagsIcon from "@material-ui/icons/LocalOffer";
import NotesIcon from "@material-ui/icons/Note";
import MediaIcon from "@material-ui/icons/PhotoLibrary";
import SettingsIcon from "@material-ui/icons/Settings";
import CategoriesIcon from "@material-ui/icons/Widgets";
import { NonPermission } from "../../NonPermission";
import { messages } from "../../language/language-es";
import { checkPermissions } from "../../utils/validations";
import { AuthorCreate, AuthorEdit, AuthorList } from "../../views/authors";
import {
  CategoriesCreate,
  CategoriesEdit,
  CategoriesList,
  CategoriesShow,
} from "../../views/categories";
import { CommentsList } from "../../views/comments";
import { ContactFormCreate } from "../../views/contact_form_create";
import {
  EntityCreate,
  EntityEdit,
  EntityList,
} from "../../views/entities/entity";
import { RelationCreate, RelationEdit } from "../../views/entities/relation";
import {
  RelationTypeCreate,
  RelationTypeEdit,
} from "../../views/entities/relation_type";
import { TypeCreate, TypeEdit, TypeList } from "../../views/entities/type";
import {
  JournalistCreate,
  JournalistEdit,
  JournalistList,
} from "../../views/journalists";
import {
  MediaCreate,
  MediaEdit,
  MediaList,
  MediaShow,
} from "../../views/media";
import ViewUsers from "../../views/metrics/view-users";
import {
  NotesCreate,
  NotesEdit,
  NotesList,
  NotesShow,
} from "../../views/notes";
import { PagesEdit, PagesList } from "../../views/pages";
import {
  PhotographersCreate,
  PhotographersEdit,
  PhotographersList,
} from "../../views/photographers";
import { RoleCreate, RoleEdit, RoleList } from "../../views/roles";
import { SettingsList } from "../../views/settings";
import {
  SubscriberCreate,
  SubscriberEdit,
  SubscriberList,
} from "../../views/subscribers";
import { TagsCreate, TagsEdit, TagsList, TagsShow } from "../../views/tags";
import ViewVisitedUrl from "../../views/visited_url/view-visitedurl";

const Resources = (permissions) => [
  <Resource
    name="notes"
    list={checkPermissions(permissions, "list-notes") ? NotesList : null}
    edit={
      checkPermissions(permissions, "edit-notes") ? NotesEdit : NonPermission
    }
    create={
      checkPermissions(permissions, "create-notes")
        ? NotesCreate
        : NonPermission
    }
    show={NotesShow}
    icon={NotesIcon}
    options={{ label: messages.resources.notes.name }}
  />,
  <Resource
    name="pages"
    list={checkPermissions(permissions, "list-pages") ? PagesList : null}
    edit={
      checkPermissions(permissions, "edit-pages") ? PagesEdit : NonPermission
    }
    icon={PagesIcon}
    options={{ label: messages.resources.pages.name }}
  />,
  <Resource
    name="media"
    list={checkPermissions(permissions, "list-media") ? MediaList : null}
    edit={
      checkPermissions(permissions, "edit-media") ? MediaEdit : NonPermission
    }
    create={
      checkPermissions(permissions, "create-media")
        ? MediaCreate
        : NonPermission
    }
    show={MediaShow}
    icon={MediaIcon}
    options={{ label: messages.resources.media.name }}
  />,
  <Resource
    name="categories"
    list={
      checkPermissions(permissions, "list-categories") ? CategoriesList : null
    }
    edit={
      checkPermissions(permissions, "edit-categories")
        ? CategoriesEdit
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-categories")
        ? CategoriesCreate
        : NonPermission
    }
    show={CategoriesShow}
    icon={CategoriesIcon}
    options={{ label: messages.resources.categories.name }}
  />,
  <Resource
    name="tags"
    list={checkPermissions(permissions, "list-tags") ? TagsList : null}
    edit={checkPermissions(permissions, "edit-tags") ? TagsEdit : NonPermission}
    create={
      checkPermissions(permissions, "create-tags") ? TagsCreate : NonPermission
    }
    show={TagsShow}
    icon={TagsIcon}
    options={{ label: messages.resources.tags.name }}
  />,
  <Resource
    name="journalists"
    list={
      checkPermissions(permissions, "list-journalists") ? JournalistList : null
    }
    edit={
      checkPermissions(permissions, "edit-journalists")
        ? JournalistEdit
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-journalists")
        ? JournalistCreate
        : NonPermission
    }
    icon={UserIcon}
    options={{ label: messages.resources.journalists.name }}
  />,
  <Resource
    name="subscribers"
    list={
      checkPermissions(permissions, "list-subscribers") ? SubscriberList : null
    }
    edit={
      checkPermissions(permissions, "edit-subscribers")
        ? SubscriberEdit
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-subscribers")
        ? SubscriberCreate
        : NonPermission
    }
    icon={UserIcon}
    options={{ label: messages.resources.subscribers.name }}
  />,
  <Resource
    name="roles"
    list={checkPermissions(permissions, "list-roles") ? RoleList : null}
    edit={
      checkPermissions(permissions, "edit-roles") ? RoleEdit : NonPermission
    }
    create={
      checkPermissions(permissions, "create-roles") ? RoleCreate : NonPermission
    }
    icon={RoleIcon}
    options={{ label: messages.resources.roles.name }}
  />,
  <Resource
    name="authors"
    list={checkPermissions(permissions, "list-authors") ? AuthorList : null}
    edit={
      checkPermissions(permissions, "edit-authors") ? AuthorEdit : NonPermission
    }
    create={
      checkPermissions(permissions, "create-authors")
        ? AuthorCreate
        : NonPermission
    }
    icon={AuthorIcon}
    options={{ label: messages.resources.authors.name }}
  />,
  /* <Resource
      name="polls"
      list={checkPermissions(permissions, "list-polls") ? PollsList : null}
      edit={checkPermissions(permissions, "edit-polls") ? PollsEdit : NonPermission}
      create={
        checkPermissions(permissions, "create-polls") ? PollsCreate : NonPermission
      }
      icon={PollIcon}
      options={{ label: messages.resources.polls.name }}
    />, */
  <Resource
    name="photographers"
    list={
      checkPermissions(permissions, "list-photographers")
        ? PhotographersList
        : null
    }
    edit={
      checkPermissions(permissions, "edit-photographers")
        ? PhotographersEdit
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-photographers")
        ? PhotographersCreate
        : NonPermission
    }
    icon={PhotographersIcon}
    options={{ label: messages.resources.photographers.name }}
  />,
  /* <Resource
      name="newspapers"
      list={
        checkPermissions(permissions, "list-newspapers") ? NewspapersList : null
      }
      edit={
        checkPermissions(permissions, "edit-newspapers")
          ? NewspapersEdit
          : NonPermission
      }
      create={
        checkPermissions(permissions, "create-newspapers")
          ? NewspapersCreate
          : NonPermission
      }
      show={NewspapersShow}
      icon={NewspapersIcon}
      options={{ label: messages.resources.newspapers.name }}
    />, */
  <Resource
    name="comments"
    list={checkPermissions(permissions, "list-comments") ? CommentsList : null}
    icon={CommentIcon}
    options={{ label: messages.resources.comments.name }}
  />,
  <Resource
    name="submenu_metrics"
    options={{
      label: messages.resources.metrics.name,
      labelUsers: messages.resources.metrics.titles.title_users,
      labelSubscriptions: messages.resources.metrics.titles.title_subscriptions,
      labelHistory: messages.resources.metrics.titles.title_history,
    }}
  />,
  <Resource
    name="metrics-users"
    list={
      checkPermissions(permissions, "list-metrics") ? ViewUsers : NonPermission
    }
  />,
  <Resource
    name="visited-url"
    list={
      checkPermissions(permissions, "list-metrics")
        ? ViewVisitedUrl
        : NonPermission
    }
  />,

  <Resource name="subscriptions" />,
  <Resource name="users" />,
  <Resource name="contact_form" create={ContactFormCreate} />,
  <Resource name="newspapers_categories" />,
];

const entityResources = (permissions) => [
  <Resource
    name="types"
    list={checkPermissions(permissions, "list-types") ? TypeList : null}
    edit={
      checkPermissions(permissions, "edit-types") ? TypeEdit : NonPermission
    }
    create={
      checkPermissions(permissions, "create-types") ? TypeCreate : NonPermission
    }
    icon={ClassIcon}
    options={{ label: messages.resources.types.name }}
  />,
  <Resource
    name="entities"
    list={checkPermissions(permissions, "list-entities") ? EntityList : null}
    edit={
      checkPermissions(permissions, "edit-entities")
        ? EntityEdit
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-entities")
        ? EntityCreate
        : NonPermission
    }
    icon={AssignmentIconDef}
    options={{ label: messages.resources.entities.name }}
  />,
  <Resource
    name="relations"
    edit={RelationEdit}
    create={RelationCreate}
    icon={CategoriesIcon}
    options={{ label: messages.resources.relations.name }}
  />,
  <Resource
    name="relation_types"
    edit={RelationTypeEdit}
    create={RelationTypeCreate}
    icon={CategoriesIcon}
    options={{ label: messages.resources.relation_types.name }}
  />,
  <Resource
    name="entitiesSub"
    options={{
      label: messages.resources.entities.plural_name,
      labelEntity: messages.resources.entities.name,
      labelType: messages.resources.types.name,
    }}
  />,
];

const Config = (permissions) => [
  <Resource
    name="settings"
    list={checkPermissions(permissions, "list-config") ? SettingsList : null}
    icon={SettingsIcon}
    options={{ label: messages.resources.settings.name }}
  />,
];

const newResources = (permissions) => {
  const custom = Resources(permissions);
  const entities = entityResources(permissions);
  const config = Config(permissions);
  return [...custom, ...entities, ...config];
};

export default newResources;
