import { lighten } from "../../../utils/sassFunction";

export const colors = [
    { name: "warning", title: "Amarillo", value: "#eed12c" },
    { name: "grey", title: "Gris", value: "#6f6f6f" },
    { name: "dark", title: "Oscuro", value: "#272727" },
    // PALETTE CUSTOM
    { name: "negro", title: "Negro", value: "#000000" },
    { name: "gris_1", title: "Gris 1", value: "#848484" },
    { name: "gris_2", title: "Gris 2", value: "#b8b8b8" },
    { name: "buenos_aires", title: "Buenos Aires", value: "#64d3d1" },
    { name: "seccion_1", title: "Sección 1", value: "#f26667" },
    { name: "seccion_2", title: "Sección 2", value: "#ffd0be" },
    { name: "seccion_3", title: "Sección 3", value: "#cecbe6" },
    { name: "seccion_4", title: "Sección 4", value: "#8f8dc4" },
    { name: "seccion_5", title: "Sección 5", value: "#509c9b" },
    { name: "seccion_6", title: "Sección 6", value: "#a8baad" },
    { name: "seccion_7", title: "Sección 7", value: "#a19d6a" },
    { name: "seccion_8", title: "Sección 8", value: "#fb7d50" },
    { name: "turismo1", title: "Turismo1", value: "#380b28" },
    { name: "turismo2", title: "Turismo2", value: "#b7f3fd" },
    { name: "turismo3", title: "Turismo3", value: "#fefddf" },
    { name: "turismocampo", title: "TurismoCampo", value: "#247c7c" },
    { name: "turismosierra", title: "Turismosierra", value: "#64473b" },
    { name: "turismomar", title: "TurismoMar", value: "#4daac8" },
    { name: "turismorio", title: "TurismoRío", value: "#f79c67" },
];

export const colorsalert = [
    { name: "light", title: "Default", value: `${lighten("#6f6f6f", 48)}` },
    { name: "success", title: "Suceso", value: "#118f63" },
    { name: "warning", title: "Alerta", value: "#e9cd2d" },
    { name: "danger", title: "Peligro", value: "#ce1d43" },
];
